import React from "react";
import { useAuth } from "../hooks/useAuth";
import couple_kissing_image from "assets/images/couple_kissing.png";
import our_sexploration_list_image from "assets/images/our_sexploration_list.jpg";
import period_tracker_image from "assets/images/period_tracker.png";
import Header from "components/header";
import { getDaysDifferenceFromDate } from "components/_globalFunctions";

export default function Home() {
  const { isLoggedIn, isAdmin, purchases, user } = useAuth();

  document.title = "Home | Uncovering Intimacy";

  const products = [
    {
      id: 1,
      name: "Spontaneous / Responsive Desire Quiz",
      href: "/SpontaneousResponsiveQuiz",
      purchaseHref: "",
      description:
        "This is a quick quiz to help you figure out if you experience spontaneous desire or responsive desire more often.",
      imageSrc: couple_kissing_image,
      purchased: true,
    },
    {
      id: 2,
      name: "Our Sexploration List",
      href: "/OurSexplorationList",
      purchaseHref: "https://www.uncoveringintimacy.com/sexlist",
      description:
        "Our Sexploration List is a resource designed to make it easier for you and your spouse to share your sexual likes, dislikes, desires, boundaries, and fantasies.",
      imageSrc: our_sexploration_list_image,
      purchased:
        purchases.filter(
          (purchase: Purchase) =>
            purchase.id == 14380 && // Bought Our Sexploration List - get 90 days free
            (getDaysDifferenceFromDate(purchase.bought) <= 90 ||
              getDaysDifferenceFromDate(new Date("2023-08-18")) <= 90)
        ).length > 0 ||
        purchases.filter(
          (purchase: Purchase) =>
            purchase.id == 45388 && // Has active $5/month subscription
            purchase.subscription.status == "active" &&
            purchase.subscription.amount >= 5
        ).length >= 1 ||
        purchases.filter(
          (purchase: Purchase) =>
            purchase.id == 45487 && // Has active $60/year subscription
            purchase.subscription.status == "active" &&
            purchase.subscription.amount >= 60
        ).length >= 1 ||
        isAdmin ||
        (user !== undefined && user.ID in [2245, 2620, 2444]), //OSL Beta Testers
    },
    {
      id: 3,
      name: "Period Tracker",
      href: "/PeriodTracker",
      purchaseHref: "",
      description:
        "This is a tool to help you track your period and ovulation cycles.",
      imageSrc: period_tracker_image,
      purchased: true,
    },
  ];

  const getMenu = () => {
    if (purchases !== undefined) {
      return (
        <Header title="Home" h1="Home" breadcrumbs={[]}>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="py-5 text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                Online Services
              </h1>
              <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
                Resources to help you in your marriage.
              </p>
            </div>

            {/* Product grid */}
            <section aria-labelledby="products-heading" className="mt-1">
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-3 xl:gap-x-8">
                {products.map((product) => (
                  <a
                    key={product.id}
                    href={
                      product.purchased ? product.href : product.purchaseHref
                    }
                    className="group"
                  >
                    <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1">
                      <img
                        src={product.imageSrc}
                        alt={product.name}
                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                      />
                    </div>
                    <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
                      <h3>{product.name}</h3>
                    </div>
                    <p className="mt-1 text-sm italic text-gray-500">
                      {product.description}
                    </p>
                  </a>
                ))}
              </div>
            </section>
          </div>
        </Header>
      );
    }
  };

  return (
    <React.Fragment>
      {isLoggedIn ? getMenu() : <p>You are not logged in.</p>}
    </React.Fragment>
  );
}

export type Purchase = {
  id: number;
  name: string;
  price: number;
  total: number;
  bought: Date;
  subscription: {
    status: string;
    amount: number;
  };
};
